import { Carousel } from "react-responsive-carousel";
import { Box, Grid, Typography, Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import ModalVariacion from "../Modal/modalVariacion";
import ModalImagen from "../Modal/modalImagen";
import WarningModal from '../Modal/modalWarning'

function Crear(prop) {
  const [images, setImages] = useState([])
  const { i18n, t } = useTranslation();
  const [openModalVariacion, setOpenModalVariacion] = useState(false)
  const [mobile, setMobile] = useState(false);
  const [openModalImagen, setOpenModalImagen] = useState(false)
  const [modalWarning, setModalWarning] = useState(false)
  const [imageModal, setImageModal] = useState(null)
  const [loadingImages, setLoadingImages] = useState([])
  const [errorMessage, setErrorMessage] = useState('Aún estamos generando una variación de tu habitación. Una vez terminada podrás solicitar hasta 15 variaciones extras.');

  const isMobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
      return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function () {
      return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
  };

  useEffect(() => {
    const newImagesCount = prop.imageOutputVariation.length - (images.length - loadingImages.length);
    const newImages = prop.imageOutputVariation.slice(0, newImagesCount);
    setImages((prev) => [...newImages, ...prev]);
    handleModalClose();
    return

    if (prop.imageOutputVariation && prop.imageOutputVariation.length > images.length) {
      // Determinar cuántas nuevas imágenes hay
      const newImagesCount = prop.imageOutputVariation.length - (images.length - loadingImages.length);
      const newImages = prop.imageOutputVariation.slice(0, newImagesCount);

      // Actualizar el estado de loading antes de las imágenes
      setLoadingImages(['./loading_work.gif']);  // El placeholder de loading

      // Agregar las nuevas imágenes al principio, seguidas del loading, luego las imágenes viejas
      setImages((prev) => [...newImages, './loading_work.gif', ...prev]);

      handleModalClose();
    } else {
      if (prop.imageOutputVariation) {
        setLoadingImages([]);
        setImages((prev) => prev.filter((img) => img !== './loading_work.gif'));
        const newImagesCount = prop.imageOutputVariation.length - (images.length - loadingImages.length);
        const newImages = prop.imageOutputVariation.slice(0, newImagesCount);
        setImages((prev) => [...newImages, ...prev]);
      }
      handleModalClose();
    }
    // if(prop.imageOutputVariation.length==0){
    //   setImages([])
    // }
  }, [prop.imageOutputVariation]);

  const handleDownload = (index, url) => {
    var base64 = getBase64Image(document.getElementById("id" + index));
    const a = document.createElement('a')
    a.download = "generacion_"+index
    a.href = base64
    a.click()
  };

  function getBase64Image(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.naturalWidth;
    canvas.height = img.naturalHeight;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image");
    return dataURL;
  }

  const handleVariacion = (formData) => {
    prop.handleVariacion(formData, 0)
  }

  const handleModalClose = () => {
    setOpenModalVariacion(false);
  };

  const handleModalOpen = () => {
    if (images.includes('./loading_work.gif')) {
      setModalWarning(true);
    }else{
      setOpenModalVariacion(true);
    }
  }

  const handleModalWarning = () => {
    setModalWarning(false);
  };

  const handleModalImagenClose = () => {
    setOpenModalImagen(false);
  };

  const handleModalImagenOpen = (image) => {
    setImageModal(image)
    setOpenModalImagen(true);
  }

  return (
    <Grid container className='options' direction="row"
      justifyContent="center"
      alignItems="center" sx={{
        '@media (min-width: 1600px)': {
          // width: '85%'
        }
      }}>
      <Grid item xs={12} sm={8} md={8} lg={7} xl={6} className="show-image">
        <Grid item sx={{
          justifyContent: 'center', display: 'flex', position: 'relative', marginLeft: '0%', marginRight: '0%',
          '@media (max-width: 600px)': {
            marginLeft: '0%', marginRight: '0%'
          },
          '@media (max-width: 1400px)': {
            marginLeft: '0%', marginRight: '0%'
          }
        }}>


          {/* <DownloadIcon onClick={(e) => { handleChange() }} sx={{ position: 'absolute', top: '50px', right: '50px' }}> */}
          {/* </DownloadIcon> */}
          <Carousel showStatus={false} showThumbs={true} class="carouselWork">
            {/* <img src={images[0]} alt="Imagen" /> */}

            {images?.map((image, index) => (
              image === './loading_work.gif' ? (
                // Loading element
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', backgroundColor: '#1F1D2B' }}>
                  <img id={"loading-carousel"} src={image} alt="Loading" style={{ width: '55px', height: '55px' }} />
                </Box>
              ) : (
                <Box onClick={(e) => { handleModalImagenOpen(image) }} sx={{ cursor: 'pointer' }} key={image}>
                  <Button size="large" onClick={(e) => { handleDownload(index, image); e.stopPropagation() }}
                    sx={{
                      position: 'absolute', top: '35px', right: '35px', color: '#ABBBC2', background: '#1f1d2b', minWidth: '25px', padding: '10px', zIndex: '1000',
                      '@media (max-width: 600px)': {
                        top: '20px', right: '20px'
                      },
                      '@media (max-width: 400px)': {
                        top: '20px', right: '35px'
                      }
                    }}
                    variant="outlined" startIcon={<DownloadIcon />}></Button>
                  <img src={image} alt="Imagen" id={"id" + index} crossOrigin="anonymous" />
                </Box>
              )
            ))}
          </Carousel>
        </Grid>

      </Grid>
      <Grid item xs={12} sm={9} md={9} lg={12} xl={12} sx={{
        display: 'grid',
        justifyContent: 'center'
      }}>
        <button class="btnEnviar" onClick={(e) => {
          handleModalOpen()
        }}>
          <Box sx={{ display: 'grid', width: '300px' }}>
            <Typography sx={{ fontFamily: 'Barlow', fontWeight: '500', fontSize: '14px', color: 'white' }}>{t("opciones")} {t("gratis")}</Typography>
          </Box>
        </button>
        {mobile &&
          <button class="btnEnviar btnSecond" onClick={(e) => {
            prop.volverFormulario();
          }}>
            <Box sx={{ display: 'grid' }}>
              <Typography sx={{ fontFamily: 'Barlow', fontWeight: '500', fontSize: '14px', color: 'white' }}>{t("nueva_habitacion")}
                {/* <ControlPointIcon sx={{ color: '#C4C4C4' }}></ControlPointIcon> */}
              </Typography>

            </Box>
          </button>
        }
      </Grid>
      <WarningModal
          open={modalWarning}
          onClose={handleModalWarning}
          message={errorMessage}
        />
      <ModalVariacion handleVariacion={handleVariacion} handleModalClose={handleModalClose} open={openModalVariacion} images={images}></ModalVariacion>
      <ModalImagen handleModalClose={handleModalImagenClose} open={openModalImagen} imageModal={imageModal}></ModalImagen>
    </Grid>
  )
}

export default Crear