import { useEffect, useState } from 'react'
import { MdCloudUpload, MdDelete } from 'react-icons/md'
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Typography, Box } from '@mui/material'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useTranslation } from 'react-i18next';
import { FileUploader } from "react-drag-drop-files";

function Uploader(prop) {
  const [image, setImage] = useState(null)
  const [base64, setBase64] = useState(null)
  const [disabled, setdDisabled] = useState(false)

  const [fileName, setFileName] = useState("No selected file")
  const { i18n, t } = useTranslation();

  useEffect(() => {
    prop.onChangeImage(base64);
  }, [base64]);

  useEffect(() => {
    setdDisabled(false)
    setFileName("No selected File")
    setImage(null)
    setBase64(null)
  }, [prop.cleanImage]);

  const onUploadImage = (files) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = (event) => {
        const img = new Image();
        
        img.onload = () => {
          // Creamos el canvas
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
  
          // Definimos el nuevo tamaño de la imagen (ajustable)
          const MAX_WIDTH = 800;
          const scaleFactor = MAX_WIDTH / img.width;
  
          canvas.width = MAX_WIDTH;
          canvas.height = img.height * scaleFactor;
  
          // Dibujamos la imagen en el canvas con el nuevo tamaño
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  
          // Comprimimos la imagen y exportamos en calidad reducida (0.7 = 70%)
          const compressedDataUrl = canvas.toDataURL('image/jpeg', 0.7);
  
          // Convertimos el DataURL a un Blob para obtener un archivo de nuevo
          fetch(compressedDataUrl)
            .then(res => res.blob())
            .then(blob => {
              const compressedFile = new File([blob], files.name, { type: 'image/jpeg' });
              setBase64(compressedFile)
              setImage(URL.createObjectURL(files))
              resolve(compressedFile);
            })
            .catch(reject);
        };
  
        img.onerror = reject;
        img.src = event.target.result; // Asignamos la URL de la imagen desde el FileReader
      };
  
      reader.onerror = reject;
      reader.readAsDataURL(files); // Leemos el archivo como DataURL
    });
  }
  const fileTypes = ["JPG", "JPEG", "PNG", "GIF", "WEBP", "BMP", "TIFF"];

  return (
    <main>
      <Box sx={{
        display: 'flex', marginBottom: '15px', marginTop: '30px', marginLeft: '2px', '@media (max-width: 1440px)': {
          marginTop: '15px'
        }
      }}>
        <UploadFileIcon sx={{ color: '#C4C4C4' }}></UploadFileIcon>
        <Typography sx={{ marginLeft: '10px', fontFamily: 'Barlow', fontWeight: '500', fontSize: '16px', color: '#C4C4C4' }}>{t("imagen")}</Typography>
      </Box>
      <FileUploader
        multiple={false}
        handleChange={onUploadImage}
        name="file"
        types={fileTypes}
        classes='input-field'
        onDrop={onUploadImage}
        disabled={disabled}
        hoverTitle={t("drop")}
      >
        <form className={'form-uploader ' + (!disabled ? 'pointer' : '')}>
          {image ?
            <div class="containerImage">
              <img class='img' src={image} alt={fileName}/>
              <button class="btn" onClick={(e) => {
                setdDisabled(false)
                setFileName("No selected File")
                setImage(null)
                setBase64(null)
              }}>
                <Box sx={{ display: 'flex' }}>
                  <DeleteOutlineIcon sx={{ color: 'white' }}></DeleteOutlineIcon>
                  <Typography sx={{ marginTop: '1px', marginLeft: '5px', fontFamily: 'Barlow', fontWeight: '500', fontSize: '14px', color: 'white' }}>{t("limpiar")}</Typography>
                </Box>
              </button>
            </div>
            :
            <>
              <Box sx={{ display: 'contents' }} >
                <img src="./static/img/Icon.png" width="30px" height="30px"  alt="icono uploader"/>
                <Typography sx={{ marginTop: '15px', fontFamily: 'Barlow', fontWeight: '500', fontSize: '16px', color: '#EA7C69' }}>{t("uploader")}</Typography>
                {/* <Typography sx={{ marginTop: '0px', fontFamily: 'Barlow', fontWeight: '400', fontSize: '12px', color: '#ABBBC2' }}>{t("uploader_sugerencia")}</Typography> */}
              </Box>
            </>
          }
        </form>
      </FileUploader>

    </main>
  )
}

export default Uploader