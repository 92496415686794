import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import "./i18n";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Importamos react-router-dom
import PrivacyPolicy from './PrivacyPolicy';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <GoogleOAuthProvider clientId="759422405476-ks9kd8bfr94srcludstid4go0tuohpnk.apps.googleusercontent.com">
    <Router>
      <Routes>
        <Route path="*" element={<App />} /> {/* Wildcard para cualquier otra ruta */}
        <Route path="/politica_de_privacidad" element={<PrivacyPolicy />} /> {/* Ruta para la Política de Privacidad */}
      </Routes>
    </Router>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
