import './modal.css';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import { Box, Typography, Button } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

const style = {
    background: '#252836',
};

export default function ModalVariacion(prop) {
    const { i18n, t } = useTranslation();
    const [errors, setErrors] = useState({
        estilo: false
    });

    const [formData, setFormData] = useState({
        estilo: ''
    });
    const style = {
        marginBottom: '2px',
        "& .MuiOutlinedInput-notchedOutline": {
            color: '#9a8c98',
            border: '#9a8c98 solid 1px'
        },
        "& .Mui-focused.MuiOutlinedInput-root": {
            color: '#f2e9e4',
        },
        "& label.Mui-focused": {
            color: "#f2e9e4"
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: "#f2e9e4"
            }
        }
    }

    const handleChange = (event) => {
        let formErrors = { ...errors };;
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        if (name == 'tipo')
            formErrors.tipo = !value ? true : false;
        if (name == 'estilo')
            formErrors.estilo = !value ? true : false;

        setErrors(formErrors);

    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let formErrors = { ...errors };;

        formErrors.tipo = !formData.tipo ? true : false;
        formErrors.estilo = !formData.estilo ? true : false;
        setErrors(formErrors);

        if (!formErrors.tipo &&
            !formErrors.estilo) {
            prop.handleVariacion(formData)
        }
    }

    return (
        <Dialog
            fullWidth={true}
            open={prop.open}
            onClose={prop.handleModalClose}
            maxWidth={'xs'}
        >
            <DialogTitle>{t("variation_title")}</DialogTitle>
            <DialogContent sx={{ overflowY: 'visible' }}>
                <DialogContentText>
                    {t("variation_text")}
                </DialogContentText>
                <Box
                    noValidate
                    component="form"
                    sx={{
                        m: 'auto',
                    }}
                >
                    <Box sx={{ marginBottom: '15px', marginTop: '20px', '@media (max-width: 1440px)': { marginBottom: '5px', marginTop: '10px' } }}>
                        <FormControl fullWidth variant="outlined" margin="normal" sx={style}>
                            <InputLabel htmlFor="tipo" className="label">
                                <Box sx={{ display: 'flex' }}>
                                    <HomeIcon sx={{ color: '#C4C4C4' }}></HomeIcon>
                                    <Typography sx={{ paddingLeft: '10px', fontFamily: 'Barlow', fontWeight: '500', fontSize: '16px', color: '#C4C4C4' }}>{t("habitacion")}</Typography>
                                </Box>
                            </InputLabel>
                            <Select
                                label="Tipo Habitacion               ab"
                                id="tipo"
                                name="tipo"
                                value={formData.tipo}
                                onChange={(e) => { handleChange(e); }}
                                MenuProps={{
                                    sx: {
                                        "&& .Mui-selected": {
                                            backgroundColor: "#9a8c98"
                                        },
                                        "&& .MuiPaper-root": {
                                            backgroundColor: "#4a4e69"
                                        }
                                    }
                                }}
                            >
                                <MenuItem value="">{t("seleccionar")}</MenuItem>
                                <MenuItem value="living">{t("living")}</MenuItem>
                                <MenuItem value="dining">{t("dining")}</MenuItem>
                                <MenuItem value="bed">{t("bed")}</MenuItem>
                                {/* <MenuItem value="kitchen">{t("kitchen")}</MenuItem> */}
                                {/* <MenuItem value="bathroom">{t("bathroom")}</MenuItem> */}
                                <MenuItem value="home_office">{t("home_office")}</MenuItem>
                                <MenuItem value="outdoor">{t("outdoor")}</MenuItem>
                                <MenuItem value="kids_room">{t("kids_room")}</MenuItem>
                            </Select>
                        </FormControl>
                        <span className={"error-message " + (errors.tipo ? 'show' : 'hide')}>{t("tooltip")}</span>
                    </Box>
                    <Box sx={{ marginBottom: '15px', marginTop: '20px', '@media (max-width: 1440px)': { marginBottom: '5px', marginTop: '10px' } }}>
                        <FormControl fullWidth variant="outlined" margin="normal" sx={style}>
                            <InputLabel htmlFor="estilo" className="label">
                                <Box sx={{ display: 'flex' }}>
                                    <ImageSearchIcon sx={{ color: '#C4C4C4' }}></ImageSearchIcon>
                                    <Typography sx={{ paddingLeft: '10px', fontFamily: 'Barlow', fontWeight: '500', fontSize: '16px', color: '#C4C4C4' }}>{t("estilo")}</Typography>
                                </Box>
                            </InputLabel>
                            <Select
                                label="Estilo                                                   abb"
                                id="estilo"
                                name="estilo"
                                value={formData.estilo}
                                onChange={(e) => { handleChange(e) }}
                                MenuProps={{
                                    sx: {
                                        "&& .Mui-selected": {
                                            backgroundColor: "#9a8c98"
                                        },
                                        "&& .MuiPaper-root": {
                                            backgroundColor: "#4a4e69",
                                            fontFamily: 'Barlow'
                                        }
                                    }
                                }}
                            >
                                {/* {estilo.map((c) => (
                <MenuItem value={c.value}>{c.name}</MenuItem>
              ))} */}
                                <MenuItem value="">{t("seleccionar")}</MenuItem>
                                <MenuItem value="standard">{t("standard")}</MenuItem>
                                <MenuItem value="industrial">{t("industrial")}</MenuItem>
                                <MenuItem value="scandinavian">{t("scandinavian")}</MenuItem>
                                <MenuItem value="modern">{t("modern")}</MenuItem>
                                <MenuItem value="farmhouse">{t("farmhouse")}</MenuItem>
                                <MenuItem value="midcentury">{t("midcentury")}</MenuItem>
                                <MenuItem value="coastal">{t("coastal")}</MenuItem>
                                <MenuItem value="luxury">{t("luxury")}</MenuItem>
                            </Select>
                        </FormControl>
                        <span className={"error-message " + (errors.estilo ? 'show' : 'hide')}>{t("tooltip")}</span>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={(event) => handleSubmit(event)}>{t("generar")}</Button>
            </DialogActions>
        </Dialog>
    );
}