import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Form from './component/Form/form';
import { Container, Menu, MenuItem, IconButton } from '@mui/material';

import './index.css';

import { PaletteMode } from '@mui/material';
import { amber, grey } from '@mui/material/colors';
import TuneIcon from '@mui/icons-material/Tune';
import { useState } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { useEffect, useLayoutEffect } from "react";
import { useGoogleLogin } from '@react-oauth/google';
import Home from './component/Home/home';
import Work from './component/Work/work';
import LoadingSpinner from "./component/Loading/LoadingSpinner";
import LoadingProgress from "./component/Loading/LoadingProgress";
import { useTranslation } from 'react-i18next';
import ModalPago from './component/Modal/modal'
import ErrorModal from './component/Modal/modalError'
import ModalMercadoPago from './component/Modal/modalMP'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TagManager from "react-gtm-module";

/* import ReactGA from 'react-ga4';
 */
const drawerWidth = 480;

const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
        // palette values for light mode
        primary: amber,
        divider: amber[200],
        text: {
          primary: grey[900],
          secondary: grey[800],
        },
      }
      : {
        // palette values for dark mode
        // primary: deepOrange,
        // divider: deepOrange[700],
        background: {
          default: '#252836',
          paper: '#252836',
        },
        text: {
          primary: '#fff',
          secondary: '#fff',
        },
      }),
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        dense: {
          '@media (max-width: 1900px)': {
            height: 90,
            minHeight: 90
          },
          '@media (max-height: 800px)': {
            height: 65,
            minHeight: 65
          }
        }
      }
    }
  },
});

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
}

export default function ResponsiveDrawer(props: Props) {
  const [mode, setMode] = React.useState<PaletteMode>('dark');
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
  const [userName, setUserName] = useState<any>(null);
  const [formData, setFormData] = useState<any>(null);
  const [image, setImage] = useState<any>(null);
  const [clickEnvio, setClickEnvio] = useState(false);
  const [showWork, setShowWork] = useState<any>(false);
  const [cleanForm, setCleanForm] = useState<any>(null);
  const [imagenes, setImagenes] = useState<any>(null);
  const [imageOutput, setImageOutput] = useState<any>(null);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Estamos procesando el máximo de solicitudes al mismo tiempo. Por favor reintentelo en 1 minuto.');
  const [renderId, setRenderId] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [mobileOpen, setMobileOpen] = useState<any>(Boolean);
  const [mobile, setMobile] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingValue, setLoadingValue] = useState(0);
  const [imagenesDisponibles, setImagenesDisponibles] = useState<any>(null);
  const { i18n, t } = useTranslation();
  const [modalPago, setModalPago] = React.useState(false);
  const [modalMercadoPago, setModalMercadoPago] = React.useState(false);
  const handleModalOpen = () => { setModalPago(true) };
  const handleModalClose = () => { setModalPago(false); setModalMercadoPago(false) };
  const apiUrl = process.env.REACT_APP_API_URL;
  const [highlight, setHighlight] = useState(false);

  /*   ReactGA.initialize('G-6WMNVH3LYX');
   */
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    // Cambia el atributo 'lang' de la etiqueta <html> cada vez que cambia el idioma
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  const setLanguage = () => {
    if (navigator.language.startsWith("es")) {
      i18n.changeLanguage("es")
    }
    if (navigator.language.startsWith("pt")) {
      i18n.changeLanguage("pt")
    }
  };

  const isMobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
      return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function () {
      return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
  };

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-N4TJ3V3B',
      events: {
        sendUserInfo: 'userInfo'
      }
    }
    TagManager.initialize(tagManagerArgs)
    redirectPlanes()
    redirectFromMail()
    getIdUrl()
    //No funciona asignacion directa, ni idea.
    if (isMobile.any()) setMobile(true);
    getPerfil()
    setLanguage()
    // getParametria()
  }, []);

  useEffect(() => {
    let imagenesDisponibles = localStorage.getItem('imagenesDisponibles');
    if (imagenesDisponibles) {
      setImagenesDisponibles(imagenesDisponibles)
    }
  }, [localStorage.getItem('imagenesDisponibles')]);

  useLayoutEffect(() => {
    let user = localStorage.getItem('userName');
    if (user) {
      setUserName(user);
      return;
    }
  });

  const redirectPlanes = () => {
    if (window.location.href.includes("planes")) {
      setModalPago(true)
    }
  }

  const getIdUrl = () => {
    var url = new URL(window.location.href);
    var idgenerar = url.searchParams.get("idgenerar");
    if (idgenerar) {
      idgenerar = decodeURIComponent(idgenerar);
      localStorage.setItem('idgenerar', idgenerar);
      fetch(apiUrl + '/api/click_mail', {
        // mode: 'no-cors',
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "idgenerar": idgenerar
        })
      })
        .then(response => response.json())
        .then(data => {
          // Guardar la respuesta de la API en el estado
          localStorage.setItem('userToken', data.jwt_token)
          localStorage.setItem('userName', data.userInfo.email.split("@")[0]);
          localStorage.setItem('email', data.userInfo.email);
          localStorage.setItem('imagenesDisponibles', data.userInfo.cantidadImagenesDisponibles)

          setUserName(data.userInfo.email.split("@")[0]);
          setIsLoading(false);
        })
    }
  }

  const redirectFromMail = () => {
    var url = new URL(window.location.href);
    var session = url.searchParams.get("session");
    if (session) {
      session = decodeURIComponent(session);
      setIsLoading(true);
      fetch(apiUrl + '/api/login_mail', {
        // mode: 'no-cors',
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "session": session
        })
      })
        .then(response => response.json())
        .then(data => {
          TagManager.dataLayer({
            dataLayer: {
              event: 'pageview',
              pagePath: '/login_mail',
              pageTitle: 'Login Mail',
            },
          });

          // Guardar la respuesta de la API en el estado
          localStorage.setItem('userToken', data.jwt_token)
          localStorage.setItem('userName', data.userInfo.name);
          localStorage.setItem('email', data.userInfo.email);
          localStorage.setItem('imagenesDisponibles', data.userInfo.cantidadImagenesDisponibles)

          setUserName(data.userInfo.name);
          setIsLoading(false);
        })
    }
  }

  const checkStatusMP = () => {
    var url = new URL(window.location.href);
    var status = url.searchParams.get("status");
    if (status == 'approved') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'pageview',
          pagePath: '/comprar/exito',
          pageTitle: 'Exito al comprar plan',
        },
      });
      setHighlight(true);
    }
    if (status == 'disapprove' || status == 'failure') {
      setOpenError(true);
      TagManager.dataLayer({
        dataLayer: {
          event: 'pageview',
          pagePath: '/comprar/error',
          pageTitle: 'Post comprar plan ERROR',
        },
      });
    }
  }

  const getPerfil = () => {
    if (!localStorage.getItem('userToken'))
      return
    fetch(apiUrl + '/api/perfil', {
      method: 'get',
      headers: {
        'token': 'Bearer ' + localStorage.getItem('userToken')
      }
    })
      .then(response => response.json())
      .then(data => {
        localStorage.setItem('imagenesDisponibles', data.CantidadImagenesDisponibles)
        checkStatusMP()
      })
  }

  const getParametria = () => {
    fetch(apiUrl + '/api/parametria', {
      method: 'get'
    })
      .then(response => response.json())
      .then(data => {
        console.log("Parametria OK")
      })
  }

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setIsLoading(true);
      localStorage.setItem('authToken', tokenResponse.access_token);
      // // Hacer la solicitud a la API
      fetch(apiUrl + '/api/login', {
        // mode: 'no-cors',
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "access_token": tokenResponse.access_token
        })
      })
        .then(response => response.json())
        .then(data => {
          TagManager.dataLayer({
            dataLayer: {
              event: 'pageview',
              pagePath: '/login',
              pageTitle: 'Login Google',
            },
          });

          // Guardar la respuesta de la API en el estado
          localStorage.setItem('userToken', data.jwt_token)
          localStorage.setItem('userName', data.userInfo.name);
          localStorage.setItem('email', data.userInfo.email);
          localStorage.setItem('imagenesDisponibles', data.userInfo.cantidadImagenesDisponibles)

          setUserName(data.userInfo.name);
          // localStorage.setItem('userToken', "PEPITOOOO")
          // localStorage.setItem('userName', "PEPITOOOO");
          // setUserName("PEPITOOOO");
          setIsLoading(false);
          if (clickEnvio) {
            formData.image = image;
            processImage(formData);
          }
        })
        .catch(error => {
          console.error('Error:', error)
          setIsLoading(false)
        });


    },
    onError: errorResponse => console.log(errorResponse),
  });

  const submitForm = (formDataSubmit: any, checked: any, imageSubmit: any) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: '/procesar/intento',
        pageTitle: 'Se intenta procesar imagen',
      },
    });
    setClickEnvio(true)
    formDataSubmit.image = imageSubmit;
    formDataSubmit.checked = checked;
    setFormData(formDataSubmit);
    setImage(imageSubmit);
    if (!localStorage.getItem('userName')) {
      clickGenerar();
      googleLogin();
      return;
    }
    processImage(formDataSubmit);
  }

  const processImage = (formData: any) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: '/procesar',
        pageTitle: 'Procesar imagen',
      },
    });
    if (localStorage.getItem('imagenesDisponibles') == '0') {
      setModalPago(true)
      return
    }
    let generar = "generar"
    let idgenerar = ""
    if (!localStorage.getItem('userName')) {
      generar = "generar_free"
      idgenerar = localStorage.getItem('idgenerar')
    }
    setLoadingValue(10)
    fetch(apiUrl + '/api/' + generar, {
      // mode: 'no-cors',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'token': 'Bearer ' + localStorage.getItem('userToken')

      },
      body: JSON.stringify({
        "image": formData.image,
        "style": formData.estilo,
        "roomType": formData.tipo,
        "declutter_mode": formData.checked == true ? 'on' : 'off',
        "idgenerar": idgenerar
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.error == 'Ya has generado una imagen con esta IP' ||
          data.error == 'Te quedaste sin imagenes'
        ) {
          setLoadingValue(0);
          setClickEnvio(true)
          googleLogin()
          return
        }
        if (data.code != undefined) {
          setOpenError(true);
          setLoadingValue(0);
          setClickEnvio(false);
          return
        }
        localStorage.setItem('imagenesDisponibles', data.cantidad_imagenes_disponibles)
        // Guardar la respuesta de la API en el estado
        setLoadingValue(23);
        syncWait(8000)
        obtenerRespuesta(0, data.generation_id, formData, 0);
        setClickEnvio(false)
        // Redireccionar a la página de contacto          
      })
      .catch(error => {
        setOpenError(true);
        console.error('Error:', error)
        setLoadingValue(0)
        setClickEnvio(false)
      });
  }

  const clickGenerar = () => {
    fetch(apiUrl + '/api/generar', {
      method: 'get',      
    })
      .then(response => response.json())
      .then(data => {
        console.log("generar")
      })
  }

  const syncWait = (ms: any) => {
    const end = Date.now() + ms
    while (Date.now() < end) continue
  }

  const obtenerRespuesta = (intento: any, generation_id: any, formData: any, variacion: any) => {
    fetch(apiUrl + '/api/status/' + generation_id, {
      // mode: 'no-cors',
      method: 'post',
      headers: {
        // 'Content-Type': 'application/json',
        'token': 'Bearer ' + localStorage.getItem('userToken')

      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status == 'rendering' || data.status == 'queued') {
          if (data.progress === 0 && variacion === 0) {
            setLoadingValue(loadingValue => {
              const nextValue = loadingValue + randomIncrement(50, loadingValue);
              return nextValue;
            });
          } else if (data.progress !== 0 && variacion === 0) {
            setLoadingValue(prevValue => {
              const nextValue = prevValue + randomIncrement(100, prevValue);
              return nextValue;
            });
          }
          if (formData.checked && variacion === 0) {
            syncWait(2000)
          } else {
            syncWait(1000)
          }
          obtenerRespuesta(intento + 1, generation_id, formData, variacion);
          return;
        }
        if (data.status == 'done' && data.progress === 0) {
          syncWait(3000)
          if (intento == 7)
            return
          obtenerRespuesta(intento + 1, generation_id, formData, variacion);
        }

        if (data.status == 'error') {
          handleVariacion(generation_id, formData, variacion)
          return;
        }
        setLoadingValue(0)
        setShowWork(true)
        setCleanForm(Math.random())
        setIsLoading(false)
        setClickEnvio(false)
        setRenderId(data.render_id)
        if (variacion < 0) {
          data.outputs = data.outputs.reverse()

          setImageOutput(data.outputs)
          syncWait(500)

          handleVariacion(generation_id, formData, variacion + 1)
        } else {
          data.outputs = data.outputs.reverse()
          setImageOutput(data.outputs)
        }
      })
      .catch(error => {
        console.error('Error:', error)
        setIsLoading(false)
        setClickEnvio(false)
      });
  }

  function randomIncrement(target: any, currentValue: any) {
    const maxLimit = target === 50 ? 49.9 : 99.9; // Limites de falso incremento
    const maxIncrement = maxLimit - currentValue; // Máximo que puede incrementar
    const increment = Math.random() * (maxIncrement / 5); // Genera un valor aleatorio

    return increment > maxIncrement ? maxIncrement : increment; // Controla el límite superior
  }

  const handleVariacion = (generation_id: any, formData: any, variacion: any) => {
    let generar = "generar"
    let idgenerar = ""
    if (!localStorage.getItem('userToken')) {
      generar = "generar_free"
      idgenerar = localStorage.getItem('idgenerar')
    }

    fetch(apiUrl + '/api/' + generar, {
      // mode: 'no-cors',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'token': 'Bearer ' + localStorage.getItem('userToken')

      },
      body: JSON.stringify({
        "generation_id": generation_id,
        "style": formData.estilo,
        "roomType": formData.tipo,
        "idgenerar": idgenerar
      })
    })
      .then(response => response.json())
      .then(data => {
        syncWait(4000)
        obtenerRespuesta(0, data.generation_id, formData, variacion);
      })
      .catch(error => {
        console.error('Error:', error)
      });
  }

  const comprar = (cantidad: any, precio: any) => {
    if (!localStorage.getItem('userToken')) {
      googleLogin()
      return
    }

    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: '/comprar/planes',
        pageTitle: 'Planes para comprar',
      },
    });
    setImagenes(JSON.stringify({
      "cantidad": cantidad,
      "precio": precio
    }))
    setIsLoading(true)
    setModalPago(false)
    setModalMercadoPago(true)
  }

  const volverFormulario = () => {
    setImageOutput("");
    setShowWork(false)
  }
  const showWork2 = () => {
    setShowWork(true)
  }

  const handleOpenError = (message: any) => {
    setErrorMessage(message);
    setOpenError(true);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    setUserName(null)
    localStorage.removeItem('userToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('email');
  };

  const drawer = (
    <div>
      <Container sx={{ background: '#1F1D2B', padding: '20px!important', borderRadius: '10px 10px 10px 10px' }}>
        <Box sx={{ position: 'relative', display: 'flex', marginBottom: '15px' }}>
          <TuneIcon></TuneIcon>
          <Typography sx={{ marginTop: '-5px', marginLeft: '10px', fontFamily: 'Barlow', fontWeight: '600', fontSize: '20px' }}>{t("title_form")}</Typography>
          {(mobile && userName) && (
            <Typography onClick={() => showWork2()} sx={{ right: '0px', position: 'absolute', marginLeft: '10px', fontFamily: 'Barlow', fontWeight: '600', fontSize: '16px', color: '#EA7C69' }}>{t("historico")}</Typography>
          )}
        </Box>
        <Divider />
        <Form submitForm={submitForm} cleanForm={cleanForm} />
      </Container>
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }} className="App">
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            borderBottom: '1px solid',
            borderBottomColor: '#abbbc259'
          }}
          elevation={0}
        >
          <Toolbar variant='dense'>
            <Box sx={{
              marginLeft: '26px', marginTop: '10px', '@media (max-width: 800px)': {
                marginLeft: '5px'
              }
            }}>
              <img className='logo' src="./static/img/MyHouseIA.png" alt="logo" />
            </Box>
            <Typography variant="h6" noWrap component="div" sx={{
              marginLeft: '10px', fontFamily: 'Barlow', fontWeight: '600', fontSize: '28px',
              '@media (max-height: 800px)': {
                fontSize: '23px'
              },
              '@media (max-height: 500px)': {
                fontSize: '19px'
              },
              '@media (max-width: 375px)': {
                fontSize: '19px'
              }
            }}>
              {t("title")}
            </Typography>

            {userName ? (
              <Box sx={{
                display: 'flex', position: 'absolute',
                right: '25px',
                '@media (max-width: 375px)': {
                  right: '20px'
                }
              }} className="box-login">

                <Typography sx={{
                  fontFamily: 'Barlow', fontWeight: '500', fontSize: '14px', color: 'white', paddingLeft: '5px', lineHeight: '1.7', paddingRight: '4px',
                  '@media (max-width: 500px)': {
                    fontSize: '13px'
                  }
                }}>{imagenesDisponibles}</Typography>
                <img className={highlight ? "highlighted-text" : ""} src="./static/img/coin.svg" alt="coin"></img>
                <Typography onClick={() => { setModalPago(true) }} sx={{
                  fontFamily: 'Barlow', fontWeight: '700', fontSize: '14px', color: 'white', paddingLeft: '5px', lineHeight: '1.7',
                  border: "2px solid #EA7C69", borderRadius: "8px", paddingRight: '5px', marginLeft: '10px', background: '#EA7C69', cursor: 'pointer',
                  '@media (max-width: 500px)': {
                    fontSize: '13px'
                  }
                }}>Planes</Typography>
                {/* <PersonOutlineIcon></PersonOutlineIcon>
                <Typography sx={{
                  fontFamily: 'Barlow', fontWeight: '500', fontSize: '14px', color: 'white', paddingLeft: '5px', lineHeight: '1.7',
                  '@media (max-width: 500px)': {
                    fontSize: '13px'
                  }
                }}>{userName}</Typography>  */}
                {/* Ícono de usuario */}
                <IconButton onClick={handleMenuOpen} sx={{
                  marginLeft: '10px', color: 'white', marginRight: '0px',
                  '@media (max-width: 500px)': {
                    paddingRight: '0px'
                  }
                }}>
                  <AccountCircleIcon />
                </IconButton>

                {/* Menú desplegable */}
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleLogout}>Salir</MenuItem>
                </Menu>
              </Box>
            )
              :
              <Box sx={{
                display: 'flex', position: 'absolute',
                right: '25px',
                '@media (max-width: 375px)': {
                  right: '20px'
                }
              }} className="box-login">
                <Typography onClick={() => { setModalPago(true) }} sx={{
                  fontFamily: 'Barlow', fontWeight: '700', fontSize: '14px', color: 'white', lineHeight: '1.7', padding: '6px 12px;',
                  borderRadius: "8px", marginRight: '20px', background: '#EA7C69', cursor: 'pointer',
                  '@media (max-width: 500px)': {
                    fontSize: '13px'
                  }
                }}>Planes</Typography>
                <button className="btnLogin" onClick={(e) => {
                  googleLogin()
                }}>
                  <Box sx={{ display: 'flex' }}>
                    <PersonOutlineIcon></PersonOutlineIcon>
                    <Typography sx={{ fontFamily: 'Barlow', fontWeight: '500', fontSize: '14px', color: 'white', paddingLeft: '5px', lineHeight: '1.7' }}>Login</Typography>
                  </Box>
                </button>
              </Box>
            }
          </Toolbar>
        </AppBar>
        {(!mobile || !showWork) && (
          <Box
            component="nav"
            mt='200px'
            sx={{
              width: { sm: drawerWidth }, flexShrink: { sm: 0 }, '@media (max-width: 1440px)': {
                width: { sm: `calc(0% + 400px)` }
              }
            }}
            aria-label="mailbox folders"
          >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            {/* <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer> */}
            <Drawer
              className="App"
              variant="permanent"
              sx={{
                display: { sm: 'block' },
                '& .MuiDrawer-paper': {
                  borderRight: '0px', boxSizing: 'border-box', width: drawerWidth,
                  '@media (max-width: 1440px)': {
                    paddingTop: '90px',
                    width: '400px'
                  },
                  '@media (max-width: 800px)': {
                    padding: '0px',
                    paddingTop: '80px',
                    width: `100%`
                  },
                  '@media (min-width: 1440px)': {
                    paddingTop: '90px'
                  },
                  '@media (max-height: 800px)': {
                    paddingTop: '70px',
                    // width: '400px'
                  },
                  padding: '26px', paddingTop: '0px', paddingBottom: '0px'
                },
              }}
              open
            >
              <Box sx={{
                padding: '20px', paddingBottom: '0px'
                ,
                '@media (max-height: 700px)': {
                  padding: '0px',
                }
              }}>
                {drawer}
              </Box>
            </Drawer>
          </Box>
        )}
        {(!mobile || showWork) && (
          <Box
            component="main"
            sx={{
              flexGrow: 1, height: '100%', width: { sm: `calc(100% - ${drawerWidth}px)` }, paddingTop: '20px', paddingRight: '20px',
              '@media (max-width: 1440px)': {
                width: { sm: `calc(100% - ${400}px)` }
              },
              '@media (max-width: 600px)': {
                paddingTop: '0px',
                paddingRight: '0px',
                width: '100%'
                // paddingLeft: '20px'
              }
            }}
          >
            <Container sx={{
              height: '100%', background: '#1F1D2B', borderRadius: '10px 10px 10px 10px', '@media (max-width: 1440px)': {
                marginTop: '90px',
                height: 'calc(100% - 125px);'
              },
              '@media (min-width: 1200px)': {
                maxWidth: '100%'
              },
              '@media (min-width: 1440px)': {
                marginTop: '90px',
                height: 'calc(100% - 131px);'
              },
              '@media (max-height: 800px)': {
                marginTop: '70px',
                height: 'calc(100% - 95px);'

              },
              '@media (max-height: 650px)': {
                height: 'calc(100% - 95px);'
              },
              '@media (max-height: 550px)': {
                height: 'calc(100% - 75px);'
              }
            }}>
              {/* <Button onClick={handleModalOpen}>Open modal</Button> */}

              {!showWork ?
                <Home setLoadingValue={setLoadingValue} />
                :
                <div style={{ height: '93%' }}>
                  {mobile &&
                    <Container maxWidth={false} sx={{
                      height: '30px', background: '#1F1D2B', paddingTop: '19px!important',
                      paddingLeft: '0px!important',
                      '@media (max-height: 650px)': {
                        height: '15px',
                        paddingTop: '10px!important'
                      }
                    }}>
                      <Box sx={{ display: 'flex' }}>
                        <ArrowBackIcon sx={{ fontSize: '20px' }}></ArrowBackIcon>
                        <Typography className={"menu "} component="span" onClick={() => volverFormulario()}
                          sx={{ fontFamily: 'Barlow', fontWeight: '600', fontSize: '12px' }}>{t("volver")}</Typography>
                      </Box>
                    </Container>
                  }
                  <Work images={imageOutput} volverFormulario={volverFormulario} setLoadingValue={setLoadingValue} renderId={renderId} />
                </div>
              }
            </Container>
          </Box>
        )}
      </Box>
      <ModalPago open={modalPago} handleModalClose={handleModalClose} comprar={comprar} />
      <ModalMercadoPago open={modalMercadoPago} handleModalClose={handleModalClose} comprar={comprar} setIsLoading={setIsLoading} imagenes={imagenes} />
      <ErrorModal
        open={openError}
        onClose={handleCloseError}
        message={errorMessage}
      />
      {isLoading && (
        <div >
          <LoadingSpinner />
        </div>
      )}
      {loadingValue != 0 && (
        <LoadingProgress value={loadingValue} />
      )}
    </ThemeProvider>
  );
}
