import { Carousel } from "react-responsive-carousel";
import { Container, Box, Grid, Typography, Button } from '@mui/material';
import { Gallery } from "react-grid-gallery";
import { useState, useEffect } from 'react'
import DownloadIcon from '@mui/icons-material/Download';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import ModalVariacion from "../Modal/modalVariacion";
import ModalImagen from "../Modal/modalImagen";
import WarningModal from '../Modal/modalWarning'

function Historico(prop) {
  const { i18n, t } = useTranslation();
  const [images, setImages] = useState([])
  const [imageOutputVariation, setImageOutputVariation] = useState([])
  const [renderId, setRenderId] = useState(null)
  const [openModalVariacion, setOpenModalVariacion] = useState(false)
  const [openModalImagen, setOpenModalImagen] = useState(false)
  const [imageModal, setImageModal] = useState(null)
  const [loadingImages, setLoadingImages] = useState([])
  const [modalWarning, setModalWarning] = useState(false)
  const [errorMessage, setErrorMessage] = useState('Aún estamos generando una variación de tu habitación. Una vez terminada podrás solicitar hasta 15 variaciones extras.');

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setHistorico();
  }, []);

  useEffect(() => {
    const newImagesCount = prop.imageOutputVariation.length - (images.length - loadingImages.length);
    const newImages = prop.imageOutputVariation.slice(0, newImagesCount);
    setImageOutputVariation((prev) => [...newImages, ...prev]);
    handleModalClose();
    return

    if (prop.imageOutputVariation && prop.imageOutputVariation.length > imageOutputVariation.length) {
      // Determinar cuántas nuevas imágenes hay
      const newImagesCount = prop.imageOutputVariation.length - (imageOutputVariation.length - loadingImages.length);
      const newImages = prop.imageOutputVariation.slice(0, newImagesCount);

      // Actualizar el estado de loading antes de las imágenes
      setLoadingImages(['./loading_work.gif']);  // El placeholder de loading

      // Agregar las nuevas imágenes al principio, seguidas del loading, luego las imágenes viejas
      setImageOutputVariation((prev) => [...newImages, './loading_work.gif', ...prev]);

      handleModalClose();
    } else {
      if (prop.imageOutputVariation) {
        setLoadingImages([]);
        setImageOutputVariation((prev) => prev.filter((img) => img !== './loading_work.gif'));
        const newImagesCount = prop.imageOutputVariation.length - (imageOutputVariation.length - loadingImages.length);
        const newImages = prop.imageOutputVariation.slice(0, newImagesCount);
        setImageOutputVariation((prev) => [...newImages, ...prev]);
        
        if(prop.imageOutputVariation.length==0){
          setImageOutputVariation([])
        }
      }
    }
    
  }, [prop.imageOutputVariation]);

  function getBase64Image(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.naturalWidth;
    canvas.height = img.naturalHeight;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image");
    return dataURL;
  }

  const handleDownload = (index) => {
    var base64 = getBase64Image(document.getElementById("id" + index));
    const a = document.createElement('a')
    a.download = "generacion_"+index
    a.href = base64
    a.click()
  };

  const setHistorico = () => {
    const historial = [];
    prop.historial.forEach((generation) => {
      historial.push({
        src: generation.imagen,
        width: 320,
      })
    });
    setImages(historial)
  }

  const clickImage = (index, image) => {
    prop.handlerLoading(true)
    setRenderId(prop.historial[index].render_id)
    fetch(apiUrl + '/api/status/' + prop.historial[index].render_id, {
      // mode: 'no-cors',
      method: 'post',
      headers: {
        // 'Content-Type': 'application/json',
        'token': 'Bearer ' + localStorage.getItem('userToken')

      }
    })
      .then(response => response.json())
      .then(data => {
        data.outputs = data.outputs.reverse()
        setImageOutputVariation(data.outputs)
        prop.handlerLoading(false)

        // compressImagesFromUrls(data.outputs).then(setImageOutputVariation);
      })
      .catch(error => {
        console.error('Error:', error)
        prop.handlerLoading(false)
      });

  }







  //FUNCIONALIDAD PARA COMPRIMIR EN FRONT TODAS LAS IMAGENES DEL HISTORICO. FUNCIONA BIEN, PERO DEMORA BASTANTE EN CORRER TODA LA COMPRESION.


  function compressImagesFromUrls(urlList) {
    const MAX_WIDTH = 800;
    const QUALITY = 0.7; // Ajusta la calidad entre 0 y 1

    // Función para procesar una sola imagen
    const processImage = (url) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'anonymous'; // Para evitar problemas con CORS

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Redimensionar según la relación de aspecto
          const scaleFactor = MAX_WIDTH / img.width;
          canvas.width = MAX_WIDTH;
          canvas.height = img.height * scaleFactor;

          // Dibujar la imagen en el canvas con el nuevo tamaño
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          // Comprimir la imagen y exportar en base64
          const compressedDataUrl = canvas.toDataURL('image/jpeg', QUALITY);
          resolve(compressedDataUrl);
        };

        img.onerror = () => reject(new Error(`Error loading image from URL: ${url}`));
        img.src = url;
      });
    };

    // Procesar todas las imágenes en paralelo
    const processAllImages = async () => {
      const compressedImagesPromises = urlList.map((url) => processImage(url));
      try {
        const compressedImagesBase64 = await Promise.all(compressedImagesPromises);
        prop.handlerLoading(false)
        return compressedImagesBase64;
      } catch (error) {
        console.error("Error processing images: ", error);
        return [];
      }
    };

    // Ejecutar la compresión de las imágenes
    return processAllImages();
  }

  const handleVariacion = (formData) => {
    prop.handleVariacion(formData, renderId)
  }

  const handleModalImagenClose = () => {
    setOpenModalImagen(false);
  };

  const handleModalImagenOpen = (image) => {
    setImageModal(image)
    setOpenModalImagen(true);
  }

  const handleModalClose = () => {
    setOpenModalVariacion(false);
  };

  const handleModalOpen = () => {
    if (imageOutputVariation.includes('./loading_work.gif')) {
      setModalWarning(true);
    }else{
      setOpenModalVariacion(true);
    }
  }

  const handleModalWarning = () => {
    setModalWarning(false);
  };

  return (
    <Box>
      {imageOutputVariation.length != 0 &&
        <Container maxWidth={false} sx={{
          background: '#1F1D2B', paddingTop: '19px!important',
          paddingLeft: '0px!important'
        }}>
          <Box sx={{ cursor: 'pointer', display: 'flex' }} onClick={() => setImageOutputVariation([])}>
            <ArrowBackIcon sx={{ fontSize: '20px' }}></ArrowBackIcon>
            <Typography className={"menu "} component="span"
              sx={{ fontFamily: 'Barlow', fontWeight: '600', fontSize: '12px' }}>{t("volver_historico")}</Typography>
          </Box>
        </Container>}

      <Box sx={{
        justifyContent: 'center', display: 'flex',
        '@media (max-width: 1400px)': {
          // marginLeft: '10%',
          // marginRight: '10%'
        },
        '@media (max-width: 1200px)': {
          marginLeft: '0%',
          marginRight: '0%'
        },
      }}>
        <Container sx={{
          height: '100%', background: '#1F1D2B', borderRadius: '10px 10px 10px 10px', '@media (max-width: 1440px)': {
            height: 'calc(100% - 110px);'
          },
          '@media (min-width: 1200px)': {
            maxWidth: '100%'
          },
          '@media (min-width: 1440px)': {
            height: 'calc(100% - 131px);'
          }
        }}>
          {imageOutputVariation.length ==0 ?
            <Box sx={{ marginTop: '20px' }}>
              <Gallery images={images} enableImageSelection={false} onClick={clickImage} />
            </Box>
            :

            <Grid container className='options' direction="row"
              justifyContent="center"
              alignItems="center" sx={{
                '@media (min-width: 1600px)': {
                  // width: '85%'
                }
              }}>
              <Grid item xs={12} sm={8} md={8} lg={7} xl={6} className="show-image">

                <Carousel showStatus={false} showThumbs={true} className="carouselWork" thumbWidth={80}>
                  {imageOutputVariation?.map((image, index) => (
                    image === './loading_work.gif' ? (
                      // Loading element
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', backgroundColor: '#1F1D2B' }}>
                        <img id={"loading-carousel"} src={image} alt="Loading" style={{ width: '50px', height: '50px' }} />
                      </Box>
                    ) : (
                      // Normal image
                      <Box onClick={(e) => { handleModalImagenOpen(image) }} sx={{ cursor: 'pointer' }} key={image}>
                        <Button size="large" onClick={(e) => { handleDownload(index); e.stopPropagation() }}
                          sx={{
                            position: 'absolute', top: '20px', right: '20px', color: '#ABBBC2', background: '#1f1d2b', minWidth: '25px', padding: '10px', zIndex: '1000',
                            '@media (max-width: 600px)': {
                              top: '20px', right: '20px'
                            },
                            '@media (max-width: 400px)': {
                              top: '20px', right: '35px'
                            }
                          }}
                          variant="outlined" startIcon={<DownloadIcon />}>
                        </Button>

                        <img src={image} alt="carousel" id={"id" + index} crossOrigin="anonymous" />
                      </Box>
                    )
                  ))}
                </Carousel>
              </Grid>
              <Grid item xs={12} sm={9} md={9} lg={12} xl={12} sx={{
                display: 'grid',
                justifyContent: 'center'
              }}>
                <button class="btnEnviar" onClick={(e) => {
                  handleModalOpen()
                }}>
                  <Box sx={{ display: 'grid', width: '300px' }}>
                    <Typography sx={{ fontFamily: 'Barlow', fontWeight: '500', fontSize: '14px', color: 'white' }}>{t("opciones")} {t("gratis")}</Typography>
                  </Box>
                </button>
              </Grid>
            </Grid>
          }
        </Container>
      </Box>
      <WarningModal
          open={modalWarning}
          onClose={handleModalWarning}
          message={errorMessage}
        />
      <ModalVariacion handleVariacion={handleVariacion} handleModalClose={handleModalClose} open={openModalVariacion}></ModalVariacion>
      <ModalImagen handleModalClose={handleModalImagenClose} open={openModalImagen} imageModal={imageModal}></ModalImagen>
    </Box>
  )
}

export default Historico